// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

import 'jquery';
import 'select2';
import '@nathanvda/cocoon';
import 'tinymce/tinymce';
import 'inputmask/dist/jquery.inputmask';
import 'daterangepicker/daterangepicker';
import 'daterangepicker/daterangepicker.css';

import '../plugins/vendor';
import '../plugins/app';

import '../application/main';

import 'notyf/notyf.min.css';

Rails.start();
ActiveStorage.start();
// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
