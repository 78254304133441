import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import { Button, Col } from 'antd';
import { HeaderRender } from 'antd/lib/calendar/generateCalendar';
import moment from 'moment';
import React from 'react';

interface IProps {
  setBlockModal: (value: boolean) => void;
  setCalendarValue: (date: moment.Moment) => void;
}

type ReactHeaderRender = {
  (props: IProps): HeaderRender<moment.Moment>;
};

const CalendarButtons: ReactHeaderRender =
  ({ setBlockModal, setCalendarValue }) =>
  ({ value }) => {
    const handleNextMonth = () => {
      setBlockModal(true);

      setCalendarValue(moment(value).add({ month: 1 }));
    };
    const handlePrevMonth = () => {
      setBlockModal(true);

      setCalendarValue(moment(value).subtract({ month: 1 }));
    };

    return (
      <>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            margin: '15px 0',
          }}
        >
          <span style={{ marginRight: 10 }}>
            {moment(`${value.month() + 1}/${value.year()})`, 'MM/YYYY')
              .locale('pt-br')
              .format('MMMM/YYYY')}
          </span>
          <Button
            onClick={handlePrevMonth}
            value="month"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <CaretLeftFilled /> Mês Anterior
          </Button>
          <Button
            onClick={handleNextMonth}
            value="year"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Próximo Mês <CaretRightFilled />
          </Button>
        </Col>
      </>
    );
  };

export default CalendarButtons;
