import styled from "styled-components";

export const TermBox = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 10px 20px;
  background-color: rgb(0, 0, 0, 4%);
  margin-bottom: 20px;
`;
