import * as editor from './editor';

jQuery(function ($) {
  editor.render();

  $('.select2').select2();
  $('.select2-multiple').select2({ multiple: true });

  $('.cep').inputmask('99999-999');
  $('.cpf').inputmask('999.999.999-99');
  $('.date').inputmask('99/99/9999');
  $('.phone').inputmask({
    mask: ['(99) 9999-9999', '(99) 99999-9999'],
    keepStatic: true,
  });

  $('[data-toggle="tooltip"]').tooltip();
  $('.datepicker').daterangepicker({
    singleDatePicker: true,
    minYear: 2021,
    maxYear: new Date().getFullYear() + 1,
    locale: {
      format: 'DD/MM/YYYY',
      separator: ' - ',
      applyLabel: 'Aplicar',
      cancelLabel: 'Cancelar',
      daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      firstDay: 0,
    },
  });

  $('#user_zipcode, #place_zipcode').on('keyup', populateAddress);

  $('.user_form', reloadUserFields);
  $('.user_form').on('change', '#user_role', reloadUserFields);
});

function reloadUserFields() {
  const role = $('#user_role').val();

  if (role == 'manager' || role == 'admin') {
    $('.user_cpf').hide();
    $('.user_gender').hide();
    $('.user_birthdate').hide();
    $('.user_phone').hide();
    $('.user_address').hide();
    $('.user_document_image').hide();
  } else {
    $('.user_cpf').show();
    $('.user_gender').show();
    $('.user_birthdate').show();
    $('.user_phone').show();
    $('.user_address').show();
    $('.user_document_image').show();
  }
}

function populateAddress() {
  const zipcode = $('#user_zipcode, #place_zipcode')
    .val()
    .match(/\d/g)
    .join('');

  if (zipcode.length == 8) {
    $.ajax(`https://viacep.com.br/ws/${zipcode}/json/`)
      .done((data) => {
        $('#user_state, #place_state').val(data.uf);
        $('#user_city, #place_city').val(data.localidade);
        $('#user_street, #place_street').val(data.logradouro);
        $('#user_neighborhood, #place_neighborhood').val(data.bairro);
      })
      .fail(() => {
        new Notyf({
          duration: 4000,
          position: { x: 'right', y: 'top' },
        }).error(
          'CEP não encontrado, verifique e digite novamente um CEP válido.'
        );
      });
  }
}
