import React from 'react';
import {
  Badge,
  BadgeProps,
  Button,
  Calendar,
  Descriptions,
  List,
  Modal,
  notification,
  Select,
  Typography,
} from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import 'rc-calendar/assets/index.css';
import 'rc-time-picker/assets/index.css';
import locale from 'antd/es/calendar/locale/pt_BR';
import 'moment/locale/pt-br';
import moment, { Moment } from 'moment';
import Axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import CalendarButtons from './CalendarButtons';

interface IProps {
  isGuardian: boolean;
  limits: number;
}

const token = document.querySelector<HTMLMetaElement>(
  'meta[name="csrf-token"]'
).content;

const ScheduleCalendar: React.FC<IProps> = (props) => {
  const [places, setPlaces] = useState([]);
  const [pets, setPets] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(undefined);
  const [selectedPet, setSelectedPet] = useState(undefined);
  const [selectedDay, setSelectedDay] = useState(undefined);
  const [selectedTime, setSelectedTime] = useState(undefined);
  const [availableHours, setAvailableHours] = useState([]);
  const [timeModal, setTimeModal] = useState(false);
  const [addSecheduleModal, setAddScheduleModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [calendarValue, setCalendarValue] = useState(moment());
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    fetchPlaces();
    fetchPets();
  }, []);

  useEffect(() => {
    handleSelectDay(calendarValue);
    setBlockModal(false);
  }, [calendarValue]);

  const fetchPlaces = async () => {
    const { data: response } = await Axios.get('/clinicas.json');

    setPlaces(response.data);
  };

  const fetchPets = async () => {
    const { data: response } = await Axios.get('/pets.json');

    setPets(response.data);
  };

  const handleDisableDate = (date: Moment) => {
    if (moment().add({ day: selectedPlace.disabled_days }).isAfter(date)) {
      return true;
    }

    if (moment().add({ day: 45 }).isBefore(date)) {
      return true;
    }

    if (selectedPlace) {
      if (selectedPlace.holidays.includes(date.format('YYYY-MM-DD'))) {
        return true;
      }

      const isWeekDay = selectedPlace.workdays.find(
        (workday) => workday.day === date.weekday()
      ).working;

      const isWorkMonth = selectedPlace.workmonths.find(
        (workmonth) => workmonth.month === date.month()
      ).working;

      return !isWeekDay || !isWorkMonth;
    }
  };

  const getListData = (calendarDate: Moment): BadgeProps[] => {
    const currentDay = calendarDate.day();
    const sameMonth = calendarDate.date() >= moment().date();
    const workday = selectedPlace.workdays[currentDay];

    return [];
  };

  const dateCellRender = (value: Moment) => {
    const listData = getListData(value);
    return (
      <div className="events">
        {listData.map((item, index) => (
          <span key={index}>
            <Badge status={item.status} text={item.text} />
          </span>
        ))}
      </div>
    );
  };

  const handleSelectPlace = (id) => {
    const place = places.find((place) => place.id === id);

    setSelectedPlace(place);

    if (place && place.alert)
      Modal.error({
        centered: true,
        width: '600px',
        okText: 'Concordo',
        title: 'ATENÇÃO!',
        icon: <ExclamationCircleOutlined />,
        content: <div dangerouslySetInnerHTML={{ __html: place.alert }} />,
      });
  };

  const handleSelectDay = async (date: Moment) => {
    if (blockModal) return;

    setSelectedDay(date);

    return Axios.get(`/clinicas/${selectedPlace.id}/horas-disponiveis.json`, {
      params: { day: date.format() },
    }).then(({ data: response }) => {
      setAvailableHours(response.data.available);

      setTimeModal(true);
    });
  };

  const handleSelectTime = (time) => {
    setSelectedTime(time);
    setTimeModal(false);
    setAddScheduleModal(true);
  };

  const handleSelectPet = (id) => {
    setSelectedPet(pets.find((pet) => pet.id === id));
  };

  const handleSchedule = () => {
    validateFields();
    setSchedules([
      ...schedules,
      {
        place: selectedPlace,
        place_id: selectedPlace.id,
        pet: selectedPet,
        pet_id: selectedPet?.id,
        schedule_at: moment(selectedTime).toDate(),
      },
    ]);
    setAddScheduleModal(false);
    setSelectedPet(undefined);
  };

  const handleScheduleOnSameDay = () => {
    handleSchedule();
    setTimeModal(true);
  };

  const validateFields = () => {
    if (
      !selectedPet ||
      (props.isGuardian && (!selectedPet.kind || !selectedPet.gender))
    ) {
      notification.error({
        message: 'Oops!',
        description: 'Preencha as informações do Pet para continuar.',
      });
      throw Error;
    }
  };

  const handleConfirmSchedules = async () => {
    setConfirmLoading(true);
    try {
      const { data: response } = await Axios.post('/agendamentos', schedules, {
        headers: {
          'X-CSRF-Token': token,
          'Content-Type': 'application/json',
        },
      });

      window.location.href = response.redirect_path;
    } catch (err) {
      console.log({ err });
      notification.error({
        message: 'Oops!',
        description: err.response.data.message,
      });
      throw Error;
    } finally {
      setConfirmLoading(false);
    }
  };

  const femaleLimitReached =
    schedules.reduce(
      (count, s) =>
        s.pet.gender === 'female' && s.pet.kind === 'dog' ? count++ : count,
      1
    ) +
      availableHours?.find((a) => a.time === selectedTime)?.female >
    selectedPlace?.female_pet_limit;

  const petGenderAvailability = (gender) => {
    if (!selectedPlace || !selectedDay) return;

    const currentDay = moment(selectedDay).day();
    const workday = selectedPlace.workdays[currentDay];

    if (workday.gender_availability === 'all') return true;

    return workday.gender_availability === gender;
  };

  const petKindAvailability = (kind) => {
    if (!selectedPlace || !selectedDay) return;

    const currentDay = moment(selectedDay).day();
    const workday = selectedPlace.workdays[currentDay];

    if (workday.kind_availability === 'all') return true;

    return workday.kind_availability === kind;
  };

  const renderGenderMessage = () => {
    if (!selectedPlace || !selectedDay) return;

    const currentDay = moment(selectedDay).day();
    const workday = selectedPlace.workdays[currentDay];

    switch (workday.gender_availability) {
      case 'male':
        return 'APENAS MACHOS';
      case 'female':
        return 'APENAS FEMEAS';
      default:
        return 'MACHOS E FEMEAS';
    }
  };

  const renderKindMessage = () => {
    if (!selectedPlace || !selectedDay) return;

    const currentDay = moment(selectedDay).day();
    const workday = selectedPlace.workdays[currentDay];

    switch (workday.kind_availability) {
      case 'dog':
        return 'APENAS CANINOS';
      case 'cat':
        return 'APENAS FELINOS';
      default:
        return 'CANINOS E FELINOS';
    }
  };

  return (
    <div>
      <Select
        placeholder="Selecione a clínica desejada."
        options={places.map((place) => ({
          label: place.name,
          value: place.id,
        }))}
        style={{ width: '100%' }}
        onSelect={handleSelectPlace}
      />

      {selectedPlace && (
        <Calendar
          locale={locale}
          headerRender={CalendarButtons({ setBlockModal, setCalendarValue })}
          disabledDate={handleDisableDate}
          dateCellRender={dateCellRender}
          onSelect={handleSelectDay}
          value={calendarValue}
        />
      )}

      <Modal
        title="Selecione o horário do agendamento:"
        visible={timeModal}
        onCancel={() => setTimeModal(false)}
      >
        <Typography>
          <Typography.Paragraph>
            <Typography.Text strong>
              Informações do dia {moment(selectedDay).format('DD/MM/YYYY')}:
            </Typography.Text>
            <ul>
              <li>
                <Typography.Text>
                  Disponibilidade de Espécie:{' '}
                  {
                    <Typography.Text underline>
                      {renderKindMessage()}
                    </Typography.Text>
                  }
                </Typography.Text>
              </li>
              <li>
                <Typography.Text>
                  Disponibilidade de Gênero:{' '}
                  {
                    <Typography.Text underline>
                      {renderGenderMessage()}
                    </Typography.Text>
                  }
                </Typography.Text>
              </li>
            </ul>
          </Typography.Paragraph>
        </Typography>
        <List>
          {availableHours.map((availableHour, index) => {
            const available =
              availableHour.available -
                schedules.filter(
                  (schedule) =>
                    schedule.schedule_at.getTime() ===
                    new Date(availableHour.time).getTime()
                ).length >
              0;

            return (
              <List.Item
                key={index}
                onClick={() =>
                  available && handleSelectTime(availableHour.time)
                }
              >
                <span
                  style={{
                    color: available ? 'rgba(0, 0, 0, 0.85)' : '#969696',
                  }}
                >
                  {moment(availableHour.time).format('HH:mm')}
                </span>
                <small
                  style={{
                    color: available ? 'rgb(43 43 43 / 85%)' : '#969696',
                  }}
                >
                  disponível: {availableHour.available}
                </small>
              </List.Item>
            );
          })}
        </List>
      </Modal>

      <Modal
        width={600}
        title="Adicionar agendamento"
        visible={addSecheduleModal}
        onCancel={() => {
          setAddScheduleModal(false);
          setSelectedPet(undefined);
        }}
        footer={
          props.isGuardian
            ? [
                <Button
                  key={1}
                  onClick={() => {
                    setAddScheduleModal(false);
                    setTimeModal(true);
                  }}
                >
                  Voltar
                </Button>,
                <Button
                  hidden={!props.isGuardian}
                  htmlType="submit"
                  key={2}
                  onClick={handleScheduleOnSameDay}
                  disabled={schedules.length == props.limits - 1}
                >
                  Agendar outro no mesmo dia
                </Button>,
                <Button
                  hidden={!props.isGuardian}
                  htmlType="submit"
                  key={3}
                  onClick={handleSchedule}
                  disabled={schedules.length == props.limits - 1}
                >
                  Agendar outro
                </Button>,
                <Button
                  key={4}
                  type="primary"
                  onClick={() => {
                    try {
                      handleSchedule();
                      setConfirmModal(true);
                    } catch (e) {}
                  }}
                >
                  Finalizar
                </Button>,
              ]
            : [
                <Button
                  key={1}
                  onClick={() => {
                    setAddScheduleModal(false);
                    setTimeModal(true);
                  }}
                >
                  Voltar
                </Button>,
                <Button
                  key={4}
                  type="primary"
                  onClick={() => {
                    try {
                      handleSchedule();
                      setConfirmModal(true);
                    } catch (e) {}
                  }}
                >
                  Finalizar
                </Button>,
              ]
        }
      >
        <div>
          {!props.isGuardian ? (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label>Pet:</label>
                {femaleLimitReached && (
                  <small>
                    Limite de cadela e gata alcançado para esse dia.
                  </small>
                )}
              </div>
              <Select
                style={{ width: '100%', marginBottom: 20 }}
                onSelect={handleSelectPet}
                placeholder="Selecione o pet desejado."
                options={pets.map((pet) => ({
                  key: pet.id,
                  label: `${pet.name} (${
                    pet.kind === 'dog' ? 'canino' : 'felino'
                  } ${pet.gender === 'male' ? 'macho' : 'fêmea'})`,
                  value: pet.id,
                  disabled:
                    schedules.find((schedule) => schedule.pet_id === pet.id) ||
                    (pet.gender === 'female' && femaleLimitReached) ||
                    !(
                      petGenderAvailability(pet.gender) &&
                      petKindAvailability(pet.kind)
                    ),
                }))}
              ></Select>
            </>
          ) : (
            <>
              <label>Espécie do pet:</label>

              <Select
                style={{ width: '100%', marginBottom: 20 }}
                onSelect={(kind) => setSelectedPet({ ...selectedPet, kind })}
                value={selectedPet?.kind}
                options={[
                  { value: 'dog', label: 'Cachorro' },
                  { value: 'cat', label: 'Gato' },
                ].map((option, index) => ({
                  key: index,
                  label: option.label,
                  value: option.value,
                  disabled: !petKindAvailability(option.value),
                }))}
              ></Select>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label>Sexo do pet:</label>
                {femaleLimitReached && (
                  <small>
                    Limite de cadela e gata alcançado para esse dia.
                  </small>
                )}
              </div>

              <Select
                style={{ width: '100%', marginBottom: 20 }}
                onSelect={(gender) =>
                  setSelectedPet({ ...selectedPet, gender })
                }
                value={selectedPet?.gender}
              >
                <Select.Option
                  value="male"
                  disabled={!petGenderAvailability('male')}
                >
                  Macho
                </Select.Option>
                <Select.Option
                  value="female"
                  disabled={
                    !petGenderAvailability('female') || femaleLimitReached
                  }
                >
                  Fêmea
                </Select.Option>
              </Select>
            </>
          )}
          {selectedPlace ? (
            <Descriptions bordered>
              <Descriptions.Item key={1} label="Clínica" span={3}>
                {selectedPlace.name}
              </Descriptions.Item>
              <Descriptions.Item key={2} label="Local" span={3}>
                {selectedPlace.street}, {selectedPlace.number} -{' '}
                {selectedPlace.neighborhood}. {selectedPlace.city} /{' '}
                {selectedPlace.state}
              </Descriptions.Item>
              <Descriptions.Item key={3} label="Dia" span={3}>
                {moment(selectedDay).format('DD/MM/YYYY')}
              </Descriptions.Item>
              <Descriptions.Item key={4} label="Horário" span={3}>
                {moment(selectedTime).format('HH:mm')}
              </Descriptions.Item>
            </Descriptions>
          ) : null}
        </div>
      </Modal>

      <Modal
        title="Confirmação de agendamentos"
        visible={schedules.length >= props.limits || confirmModal}
        width={1200}
        okText="Confirmar agendamentos"
        cancelText="Cancelar"
        confirmLoading={confirmLoading}
        onOk={handleConfirmSchedules}
        onCancel={() => {
          if (
            window.confirm(
              'Você tem certeza que deseja limpar todos os agendamentos?'
            )
          ) {
            setSchedules([]);
            setConfirmModal(false);
          }
        }}
      >
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 1,
            lg: schedules.length > 1 ? 2 : 1,
            xl: schedules.length > 1 ? 2 : 1,
            xxl: schedules.length > 1 ? 2 : 1,
          }}
          dataSource={schedules}
          renderItem={(schedule, index) => (
            <List.Item>
              <Descriptions
                key={`${index}00`}
                title={`Agendamento #${index + 1}`}
                bordered
              >
                <Descriptions.Item key={`${index}0`} label="Pet" span={3}>
                  {props.isGuardian
                    ? `${schedule.pet.kind == 'dog' ? 'Cachorro' : 'Gato'} - ${
                        schedule.pet.gender == 'male' ? 'Macho' : 'Fêmea'
                      }`
                    : `${schedule.pet.name} - ${schedule.pet.breed}`}
                </Descriptions.Item>
                <Descriptions.Item key={`${index}2`} label="Clínica" span={3}>
                  {schedule.place.name}
                </Descriptions.Item>
                <Descriptions.Item key={`${index}3`} label="Local" span={3}>
                  {schedule.place.street}, {schedule.place.number} -{' '}
                  {schedule.place.neighborhood}. {schedule.place.city} /{' '}
                  {schedule.place.state}
                </Descriptions.Item>
                <Descriptions.Item key={`${index}4`} label="Dia" span={3}>
                  {moment(schedule.schedule_at).format('DD/MM/YYYY')}
                </Descriptions.Item>
                <Descriptions.Item key={`${index}5`} label="Horário" span={3}>
                  {moment(schedule.schedule_at).format('HH:mm')}
                </Descriptions.Item>
              </Descriptions>
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};

export default ScheduleCalendar;
