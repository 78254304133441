import React, { useState } from 'react';
import { Button, Calendar, Checkbox, Typography } from 'antd';
import { TermBox } from './schedule.style';
import ScheduleCalendar from './Calendar';

import 'antd/dist/antd.css';

interface IProps {
  isGuardian: boolean;
  limits: number;
}

const Schedule: React.FC<IProps> = (props) => {
  const [checked, setChecked] = useState(false);
  const [onCalendar, setOnCalendar] = useState(false);

  const handleAcceptTerms = () => {
    setChecked(!checked);
  };

  return (
    <>
      {onCalendar ? (
        <ScheduleCalendar {...props} />
      ) : (
        <>
          <Typography>
            Antes de iniciar o agendamento, é preciso concordar que está ciente
            e concorda com os termos da castração:
          </Typography>
          <Typography style={{ textAlign: 'justify' }}>
            <ul>
              {props.isGuardian && (
                <>
                  <li>
                    Cada protetor terá direito a 8 animais por mês, sendo 2
                    cães, 2 cadelas e 4 felinos;
                  </li>
                  <li>
                    As vagas de cães e cadelas poderão ser trocadas por felinos;
                  </li>
                  <li>
                    As vagas de felinos não poderão ser trocadas por cães;
                  </li>
                </>
              )}
              <li>O animal precisará residir no Estado do Rio de Janeiro;</li>
              <li>O animal deverá ter no mínimo 6 meses de idade;</li>
              <li>O animal deverá ter no máximo 7 anos de idade;</li>
              <li>Animais braquicefálicos não poderão ser castrados, exceto nas unidades de castramóvel do município do rio de janeiro;</li>
              <li>
                O animal deverá ter o peso mínimo de 3 kg e máximo de 25kg;
              </li>
              <li>
                O animal deve estar em jejum absoluto (água e comida) por um
                período de 8 horas antes da cirurgia;
              </li>
              <li>
                Se possível, dê banho no animal um dia antes do procedimento;
              </li>
              <li>
                O animal não pode ter sido vacinado com menos de 21 dias da data
                do procedimento;
              </li>
              <li>
                No dia da cirurgia, indicar ao veterinário caso o animal esteja
                fazendo uso de algum medicamento;
              </li>
              <li>
                Fêmeas no cio, gestantes ou que estão amamentando não poderão
                ser castradas;
              </li>
              <li>
                Cães (machos e fêmeas) devem ser levados com guias e coleiras.
                Caso sejam agressivos, usar focinheira;
              </li>
              <li>
                Cães e gatos devem ter os dois testículos no saco escrotal;
              </li>
              <li style={{ fontWeight: 'bold' }}>
                O animal passará pela avaliação do veterinário antes da
                cirurgia, e caso ele julgue necessário, o animal não será
                castrado;
              </li>
              <li>
                O responsável deverá levar uma cópia da identidade, CPF e
                comprovante de residência no dia da cirurgia;
              </li>
              <li>
                O responsável deverá permanecer na clínica veterinária durante
                todo o procedimento;
              </li>
              <li>
                Caso não seja a pessoa cadastrada a levar o animal no dia
                agendado, deverá enviar uma declaração de próprio punho
                autorizando outra pessoa a levar (com nome completo e CPF).
              </li>
            </ul>
          </Typography>

          {props.limits > 0 ? (
            <>
              <TermBox>
                <Checkbox onChange={handleAcceptTerms}>
                  Estou ciente e quero prosseguir
                </Checkbox>
              </TermBox>
              <Button
                block
                type="primary"
                size="large"
                disabled={!checked}
                onClick={() => setOnCalendar(true)}
              >
                Criar novo agendamento
              </Button>
            </>
          ) : (
            <>
              <Button block type="primary" size="large" disabled={true}>
                Você atingiu seu limite mensal de agendamentos.
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Schedule;
